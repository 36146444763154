import React from "react";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import CommissionBadge from "../components/CommissionBadge";
import Support from "../pages/support/Support";
import BlockedId from "../components/BlockedId";

function AppLayout({ children }) {
  return (
    <>

    <div className="w-full min-h-screen flex bg-slate-100">
      <div className="w-full max-w-[550px]  min-h-full bg-gray-50 shadow">
        <div className="relative ">
          <SideBar/>
        <BlockedId/>
          <Header />
          <div className="p-2 w-full ">
          {children}
          </div>
        </div>
      </div>
    </div>

    <Support/>
    </>
  );
}

export default AppLayout;
