import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

function BlockedId() {
    const {user}=useSelector(s=>s.userReducer)
const {pathname} = useLocation()

    useEffect(()=>{
if(user&&user?.status==="blocked"){
    Modal.error({
        content: "Your Id has been blocked contact admin",
      });
}
    },[user,pathname])
return
}

export default BlockedId