import { Button, Card, Form, Input, Typography, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import socket from "../../helper/socket";
import { BATTLE_STATUS, SOCKET_EMITS } from "../../helper/Constants";
import OpenCard from "./cards/OpenCard";
import RunningCard from "./cards/RunningCard";
import { GiLaurelsTrophy, GiBattleGear } from "react-icons/gi";
import { GetUser } from "../../redux/actions/user.action";

function moveToTop(array, predicate) {
  let ar = array;
  const index = ar.findIndex(predicate);

  if (index !== -1) {
    const obj = ar.splice(index, 1)[0];
    ar.unshift(obj);
  }
  return ar;
}

function Battles() {
  const { user } = useSelector((s) => s.userReducer);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [battles, setBattles] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [myopenBattles,setMyOpenBattles]=useState()

  const onSubmit = async (values) => {
    if (socket.connected) {
      setLoading(true);
      values.userId = user?.key_id;
      values.gameId = id;
      socket.emit(SOCKET_EMITS.CREATE_BATTLE, values);
    } else {
      socket.connect();
    }
  };
  useMemo(() => {
    socket.on(SOCKET_EMITS.CREATE_BATTLE, (res) => {
      console.log("socket battle emit", res);
      if (res?.success) {
        message.success(res?.message);
      } else {
        message.error(res?.message);
      }
      setLoading(false);
      return;
    });

    //get battles
    socket.on(SOCKET_EMITS.GET_BATTLES, async (data) => {
      if (data) {
        if (data[id]) {
          setBattles(
            moveToTop(data[id], (obj) => obj.createrId === user?.key_id)
          );
        }
      }
    });

    socket.on(SOCKET_EMITS.BATTLE_STARTED, async (data) => {
      if (data?.createrId === user?.key_id || data?.joinerId === user?.key_id) {
        dispatch(GetUser());
        navigate(`/battles/battle/${data?._id}`);
      }
    });
    //   return ()=>socket.disconnect()
  }, []);
  useEffect(() => {
    socket.emit(SOCKET_EMITS.GET_BATTLES);
  }, []);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex items-center flex-col gap-1">
        <Typography.Text type="secondary">Create a battle!</Typography.Text>
        <Form
          className="flex justify-between gap-1 w-[90%]"
          layout="vertical"
          onFinish={onSubmit}
        >
          <Form.Item name={"amount"} className="w-full">
            <Input
              placeholder="Amount"
              className="input-primary"
              inputMode="numeric"
            />
          </Form.Item>
          <Button
            className="flex-1 btn-primary bg-slate-900 text-white font-bold"
            type="default"
            htmlType="submit"
            loading={loading}
          >
            SET
          </Button>
        </Form>
      </div>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-4">
          <b className="flex items-center gap-2">
            <GiLaurelsTrophy className="text-lg" /> Open Battles
          </b>
          <div className="flex flex-col gap-2">
            {battles?.map((b, i) => {
              if (
                b?.status == BATTLE_STATUS.open ||
                b?.status === BATTLE_STATUS.requested
              ) {
                if (
                  b?.createrId === user?.key_id ||
                  b?.joinerId === user?.key_id
                ) {
                  return <OpenCard battle={b} key={i} />;
                }
              }
            })}
            {battles?.map((b, i) => {
              if (b?.status == BATTLE_STATUS.open) {
                if (
                  b?.createrId !== user?.key_id &&
                  b?.joinerId !== user?.key_id
                ) {
                  return <OpenCard battle={b} key={i} />;
                }
              }
            })}
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <b className="flex items-center gap-2">
            <GiBattleGear className="text-lg" /> Running Battles
          </b>
          <div className="flex flex-col gap-2">
            {battles?.map((b, i) => {
              if (b?.status == BATTLE_STATUS.running) {
                if (
                  b?.createrId === user?.key_id ||
                  b?.joinerId === user?.key_id
                ) {
                  return <RunningCard battle={b} key={i} />;
                }
              }
            })}
            {battles?.map((b, i) => {
              if (b?.status == BATTLE_STATUS.pending) {
                if (
                  b?.createrId === user?.key_id ||
                  b?.joinerId === user?.key_id
                ) {
                  return <RunningCard battle={b} key={i} />;
                }
              }
            })}
            {battles?.map((b, i) => {
              if (b?.status == BATTLE_STATUS.running) {
                if (
                  b?.createrId !== user?.key_id &&
                  b?.joinerId !== user?.key_id
                ) {
                  return <RunningCard battle={b} key={i} />;
                }
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Battles;
