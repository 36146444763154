import React, { useEffect, useState } from "react";
import { get } from "../../helper/api";
import { Button, List, Skeleton, Tabs, Tag, Typography, message } from "antd";
import moment from "moment";
import { priceInRupees } from "../../helper/helper";
import { useSelector } from "react-redux";
import { BATTLE_STATUS, WITHDRAWAL_STATUS } from "../../helper/Constants";
import { STORE } from "../../redux/Store";
import { GetUser } from "../../redux/actions/user.action";

function WalletHistory() {
  const { user } = useSelector((s) => s.userReducer);
  const [history, setHistory] = useState([]);
  const [withdrawHistory, setWithdrawHistory] = useState([]);
  const [battleHistory, setBattleHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [tab, setTab] = useState("1");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(1);
  const onLoadMore = () => {
    setPage((prev) => prev + 1);
  };

  const loadMore = page < total && (
    <div
      style={{
        textAlign: "center",
        marginTop: 12,
        height: 32,
        lineHeight: "32px",
      }}
    >
      <Button onClick={onLoadMore} type="text" loading={loading}>
        Load more
      </Button>
    </div>
  );

  const getData = async () => {
    setLoading(true);

    const query = {
      page: page,
      limit: limit,
    };
    if (tab === "1") {
      const res = await get("/wallet/history", query);
      if (res?.success) {
        const data = history.concat(res?.history);
        setTotal(res?.totalPages);
        setHistory(data);
      }
      setLoading(false);
      return;
    }
    if (tab === "3") {
      const res = await get("/wallet/withdraw-history", query);
      if (res?.success) {
        const data = withdrawHistory.concat(res?.history);
        setTotal(res?.totalPages);
        setWithdrawHistory(data);
      }
      setLoading(false);
      return;
    }
    if (tab === "2") {
      const res = await get("/battle/history/get", query);
      if (res?.success) {
        const data = battleHistory.concat(res?.history);
        setTotal(res?.totalPages);
        setBattleHistory(data);
      }
      setLoading(false);
      return;
    }
  };

  const cancelWithdraw = async (id) => {
    setCancelling(true);
    const res = await get(`/wallet/cancel-withdraw/${id}`);
    if (res?.success) {
   
      message.success(res?.message);

      setTab("1")
      STORE.dispatch(GetUser())
    }else{
      message.error(res?.message)
    }
    setCancelling(false);
    return;
  };

  const items = [
    {
      key: "1",
      label: "Wallet",
      children: (
        <List
          dataSource={history}
          loading={loading}
          loadMore={loadMore}
          renderItem={(item) => {
            return (
              <List.Item className="mb-2 grid grid-cols-2 gap-2">
                <div className="flex flex-col ">
                  <Typography.Text className="capitalize text-xs font-semibold">
                    {item?.label}
                  </Typography.Text>
                  <Typography.Text type="secondary" className="text-[12px]">
                    Id: {item?.txnId}
                  </Typography.Text>
                  <Typography.Text type="secondary">
                    {moment(item?.createdAt).format("DD-MM-YYYY, hh:mm a")}
                  </Typography.Text>
                </div>
                <div className="flex flex-col items-end">
                  <Typography.Text className="font-semibold">
                    {item?.oprator} {priceInRupees(item?.amount)}
                  </Typography.Text>
                  <Typography.Text type="secondary" className="text-xs">
                    {Number(item?.closing)?.toFixed(1)}/-
                  </Typography.Text>
                </div>
              </List.Item>
            );
          }}
        ></List>
      ),
    },
    {
      key: "2",
      label: "Battle",
      children: (
        <List
          dataSource={battleHistory}
          loading={loading}
          loadMore={loadMore}
          renderItem={(item) => {
            const isWinner = item?.winner === user?.key_id ? true : false;
            if (item?.status === BATTLE_STATUS.cancelled) {
              return (
                <List.Item className="mb-2 grid grid-cols-2 gap-2">
                  <div className="flex flex-col ">
                    <Typography.Text className="capitalize text-xs font-semibold text-red-500">
                      {item?.status}
                    </Typography.Text>
                    <Typography.Text type="secondary" className="text-[12px]">
                      Refunded
                    </Typography.Text>
                    <Typography.Text type="secondary" className="text-xs">
                      {moment(item?.createdAt).format("DD-MM-YYYY, hh:mm a")}
                    </Typography.Text>
                  </div>
                  <div className="flex flex-col items-end">
                    <Typography.Text className={`font-semibol text-yellow-600`}>
                      {" "}
                      {priceInRupees(item?.amount)}
                    </Typography.Text>
                    {/* <Typography.Text type='secondary' className='text-xs'>{Number(item?.closing)?.toFixed(1)}/-</Typography.Text> */}
                  </div>
                </List.Item>
              );
            }
            return (
              <List.Item className="mb-2 grid grid-cols-2 gap-2">
                <div className="flex flex-col ">
                  <Typography.Text className="capitalize text-xs font-semibold">
                    {isWinner
                      ? `Win Against ${
                          item?.losser === item?.createrId
                            ? item?.creater_name
                            : item?.joiner_name
                        }`
                      : `Lost Against ${
                          item?.losser === item?.createrId
                            ? item?.creater_name
                            : item?.joiner_name
                        }`}
                  </Typography.Text>
                  <Typography.Text type="secondary" className="text-[12px]">
                    Room: {item?.roomCode}
                  </Typography.Text>
                  <Typography.Text type="secondary" className="text-xs">
                    {moment(item?.createdAt).format("DD-MM-YYYY, hh:mm a")}
                  </Typography.Text>
                </div>
                <div className="flex flex-col items-end">
                  <Typography.Text
                    className={`font-semibold ${
                      isWinner ? "text-green-500" : "text-red-500"
                    }`}
                  >
                    {isWinner ? "+" : "-"}{" "}
                    {priceInRupees(
                      isWinner ? item?.prize - item?.amount : item?.amount
                    )}
                  </Typography.Text>
                  {/* <Typography.Text type='secondary' className='text-xs'>{Number(item?.closing)?.toFixed(1)}/-</Typography.Text> */}
                </div>
              </List.Item>
            );
          }}
        ></List>
      ),
    },
    {
      key: "3",
      label: "Withdraws",
      children: (
        <List
          dataSource={withdrawHistory}
          loading={loading}
          loadMore={loadMore}
          renderItem={(item) => {
            return (
              <List.Item className="mb-2 grid grid-cols-2 gap-2">
                <div className="flex flex-col ">
                  <Typography.Text
                    className={`capitalize text-xs font-semibold ${
                      item?.status === WITHDRAWAL_STATUS.approved
                        ? "text-green-500"
                        : item?.status === WITHDRAWAL_STATUS?.pending
                        ? "text-yellow-500"
                        : "text-red-500"
                    }`}
                  >
                    {item?.status}
                  </Typography.Text>
                  <Typography.Text type="secondary" className="text-[12px]">
                    Id: {item?._id}
                  </Typography.Text>
                  <Typography.Text type="secondary">
                    {moment(item?.createdAt).format("DD-MM-YYYY, hh:mm a")}
                  </Typography.Text>
                </div>
                <div className="flex flex-col items-end">
                  <Typography.Text className="font-semibold">
                    {item?.oprator} {priceInRupees(item?.amount)}
                  </Typography.Text>
                  {item?.status === WITHDRAWAL_STATUS.pending &&!cancelling&& (
                    <Tag
                      className="cursor-pointer bg-PRIMARY text-white"
                      onClick={() => cancelWithdraw(item?._id)}
                    
                    >
                      Cancel
                    </Tag>
                  )}
                </div>
              </List.Item>
            );
          }}
        ></List>
      ),
    },
  ];

  useEffect(() => {
    setBattleHistory([]);
    setHistory([]);
    setWithdrawHistory([]);
    getData();
  }, [tab]);
  useEffect(() => {
    getData();
  }, [page]);

  useEffect(() => {
    setPage(1);
  }, [tab]);

  return (
    <div className="px-2">
      <Tabs items={items} activeKey={tab} onChange={(e) => setTab(e)} />
    </div>
  );
}

export default WalletHistory;
