import React, { useEffect, useState } from "react";
import AppContext from "./context";
import socket from "../helper/socket";
// import { generateToken } from "../firebase/firebase";
import { useSelector } from "react-redux";
import { message } from "antd";
import { LOCALDATAKEYS, SOCKET_EMITS } from "../helper/Constants";
import { useLocation } from "react-router-dom";

function AppStates({ children }) {
  const [sideBar, setSideBar] = useState(false);
  const { user } = useSelector((s) => s.userReducer);
  const [FCMToken, setFCMToken] = useState();
  const {pathname} = useLocation()
  // const token = async () => {
  //   const res = await generateToken();
  //   if (res?.status) {
  //     setFCMToken(res?.token);
  //   }
  // };
  message.config({
    top: 1,
    duration: 2,
    maxCount: 1,
    rtl: false,
    prefixCls: 'my-message',
  });
  useEffect(() => {
    const token = localStorage.getItem(LOCALDATAKEYS.AUTH_TOKEN)
    // token();
    // socket.on('connect', () => {
    //   console.log('Connected to server');
    // });
    const handleFocus = () => {
      if (document.hasFocus()&&token) {
    
          socket.connect();
        
          
        socket.emit(SOCKET_EMITS.GET_BATTLES)
      } else {
        // socket.disconnect();
      }
    };

    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleFocus);
    return () => {
      // newSocket.disconnect();
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleFocus);
    };
  }, [user]);
 
  useEffect(()=>{
if(sideBar){
  setSideBar(false)
}

  },[pathname])

  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  const onClick = (evt) => {
  
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
   promptInstall.prompt()
  };

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);


  useEffect(() => {
    return
    if (FCMToken && user) {
      if (user?.FcmToken) {
        if (user?.FcmToken !== FCMToken) {
          socket.emit("FCM", { FCMToken, userId: user?._id });
        }
      } else {
        socket.emit("FCM", { FCMToken, userId: user?._id });
      }
    }
  }, [user, FCMToken]);
  return (
    <AppContext.Provider value={{ sideBar, setSideBar,onClick }}>
      {children}
    </AppContext.Provider>
  );
}

export default AppStates;
