export const AppName = "Baba Ludo";
export const AppDomain = "https://babaludo.com";

// export const SERVER_URL = "http://localhost:8000";
// export const SERVER_URL ="http://192.168.1.41:8000"
// export const SERVER_URL ="http://192.168.127.109:8000"
export const SERVER_URL = "https://api.babaludo.com";

export const DEFAULT_USER_IMAGE =
  "https://cdn2.vectorstock.com/i/1000x1000/18/11/man-profile-cartoon-vector-19491811.jpg";
