import { Button, Card, Spin, message } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import socket from "../../../helper/socket";
import { BATTLE_STATUS, SOCKET_EMITS } from "../../../helper/Constants";
import { DEFAULT_USER_IMAGE } from "../../../helper/Configs";
import { GiMoneyStack } from "react-icons/gi";
import moment from "moment";
function OpenCard({ battle }) {
  const { user } = useSelector((s) => s.userReducer);
  const rootUser = user?.key_id === battle?.createrId;

  const start = () => {
    socket.emit(SOCKET_EMITS.START_PLAY, { battleId: battle?._id });
  };
  const playRequest = () => {
    socket.emit(SOCKET_EMITS.PLAY, { battleId: battle?._id });
  };
  const cancelRequest = () => {
    socket.emit(SOCKET_EMITS.CANCEL_REQUEST, { battleId: battle?._id });
  };
  const cancelBattle = () => {
    socket.emit(SOCKET_EMITS.CANCEL_BATTLE, { battleId: battle?._id });
  };
  socket.on(SOCKET_EMITS.PLAY, (data) => {
    if (data?.success) {
      // message.success(data?.message)
    } else {
      message.error(data?.message);
    }
  });
  return (
    <Card
      className=" rounded-lg bg-white flex flex-col"
      size="small"
      title={
        <div className="flex items-center justify-between h-full p-1">
          <p className="text-xs font-thin">
            Challenge from {battle?.creater_name || battle?.createrId}
            {/* on {moment(battle?.createdAt).format("DD, hh:mm a")} */}
          </p>
          {battle?.blockAction && (
            <Button type="text" loading disabled>
              Starting..
            </Button>
          )}
          {rootUser && !battle?.blockAction ? (
            <>
              {battle?.status === BATTLE_STATUS.open && (
                <Button
                  type="default"
                  className="bg-red-500 text-white h-[30px] text-xs"
                  onClick={cancelBattle}
                >
                  Cancel
                </Button>
              )}
              {battle?.status === BATTLE_STATUS.requested && (
                    <div className="flex items-center gap-4">
                    <Button
                      type="default"
                      className="bg-red-500 text-white h-[30px] text-xs"
                      onClick={cancelBattle}
                    >
                      Cancel
                    </Button>
                <Button
                  type="default"
                  className="bg-green-500 text-white h-[30px] text-xs"
                  onClick={start}
                >
                  Start
                </Button>
                </div>
              )}
            </>
          ) : battle?.status === BATTLE_STATUS.open ? (
            <Button
              type="default"
              className="bg-slate-500 text-white h-[30px] text-xs"
              b
              onClick={playRequest}
            >
              Play
            </Button>
          ) : (
            !battle?.blockAction && (
          
              <Button
                type="default"
                className="bg-slate-500 text-white h-[30px] text-xs"
                onClick={cancelRequest}
              >
                Requested
              </Button>
           
            )
          )}
        </div>
      }
    >
      <div className="grid grid-cols-2">
        <div className="flex gap-5">
          <div className="flex flex-col gap-2">
            <p className="text-xs font-extralight">Entry Fee</p>
            <b className="flex items-center gap-2">
              <GiMoneyStack className="text-green-500" size={20} />
              {battle?.amount}
            </b>
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-xs font-extralight">Prize</p>
            <b className="flex items-center gap-2">
              <GiMoneyStack className="text-green-500" size={20} />
              {battle?.prize}
            </b>
          </div>
        </div>
        <div>
          {rootUser && battle?.status === BATTLE_STATUS.open && (
            <div className="flex flex-col gap-2 items-center">
              <Spin />
              <p className="text-xs font-semibold capitalize">
                waiting for player
              </p>
            </div>
          )}
          {battle?.status === BATTLE_STATUS.requested && (
            <div className="flex flex-col gap-2 items-center">
              <img
                src={DEFAULT_USER_IMAGE}
                alt="userImage"
                className="w-[30px] h-[30px] rounded-full"
              />
              <b className="text-xs">
                {battle?.joiner_name || battle?.joinerId}
              </b>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}

export default OpenCard;
