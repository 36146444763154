import { Button, Form, Input, Modal, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { put } from "../../helper/api";
import { GetUser } from "../../redux/actions/user.action";


function UpdateUserName({ ...rest }) {
  const { user } = useSelector((s) => s.userReducer);
  const [form] = useForm();
  const [modal, setModal] = useState(false);
  const [loading,setLoading]=useState(false)
  const dispatch=useDispatch()

  const onFinish=async(vals)=>{
    vals.username = vals?.username?.toLowerCase()
if(vals?.username?.split(" ")?.length>1){
  return message.error("UserName Sholud not contain Space")
}
    setLoading(true)
    const res = await put("/auth/update-user",vals)
    if(res?.success){
        message.success(res?.message)
        setModal(false)
        dispatch(GetUser())
    }else{
        message.error(res?.message)
    }
    setLoading(false)

  }

  return (
    <>
      <Modal
        open={modal}
        onCancel={() => setModal(false)}
        footer={false}
        title="Update User Name"
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            username: user?.username || user?.name || "",
          }}
          onFinish={onFinish}
        >
          <Form.Item label="Username" name={"username"} rules={[{required:true}]}>
            <Input className="lowercase" maxLength={20}/>
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={loading} >Update</Button>
        </Form>
      </Modal>

      <Button onClick={() => setModal(true)} {...rest}>
        Update
      </Button>
    </>
  );
}

export default UpdateUserName;
