export const LOCALDATAKEYS = {
  AUTH_TOKEN: "AUTH_TOKEN",
};

export const SOCKET_EMITS = {
  CREATE_BATTLE: "CREATE_BATTLE",
  GET_BATTLES:"GET_BATTLES",
  CANCEL_BATTLE:"CANCEL_BATTLE", 
  CANCEL_REQUEST:"CANCEL_REQUEST",  
  PLAY:"PLAY" ,
  START_PLAY:"START_PLAY",
  UPDATE_ROOM_CODE:"UPDATE_ROOM_CODE",
  BATTLE_STARTED:"BATTLE_STARTED",
};


export const BATTLE_STATUS={
    open:"open",
    requested:"requested",
    running:"running",
    responed:"responed",
    complete:"complete",
    pending:"pending",
    cancelled:"cancelled",
    timeout:"timeout"
}


export const BATTLE_RESPONSES={
    win:"win",
    lose:"lose",
    cancel:"cancel"
    }

export const KYC_STATUS={
      pending:"pending",
      approved:"approved",
      declined:"declined"
      }


      export const WITHDRAWAL_STATUS = {
        pending: "pending",
        approved: "approved",
        declined: "declined",
      };