import { get } from "../../helper/api"
import { GET_GAMES } from "../types/games.types"



export const GetGames=()=>{
    return async(dispatch)=>{
        const res = await get("/games")
        if(res?.success){
            dispatch({
                type:GET_GAMES,
                payload:res?.games
            })
        }
    }
}