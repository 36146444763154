
import { SERVER_URL } from './Configs';
import { LOCALDATAKEYS } from './Constants';
const {io} = require( 'socket.io-client');

const token = localStorage.getItem(LOCALDATAKEYS.AUTH_TOKEN)

const socket = io(SERVER_URL,{
    auth:{
        token:token
    }
})

export default socket