import Login from "./pages/auth/Login";
import BattleRoom from "./pages/battle/BattleRoom";
import Battles from "./pages/battle/Battles";
import Home from "./pages/home/Home";
import LegalTerms from "./pages/policy/LegalTerms";
import AddCash from "./pages/wallet/AddCash";
import Wallet from "./pages/wallet/Wallet";
import WalletHistory from "./pages/wallet/WalletHistory";
import { FaPlay,FaHistory ,FaClipboardList} from "react-icons/fa";
import { IoIosWallet } from "react-icons/io";
import Withdraw from "./pages/wallet/Withdraw";
import Kyc from "./pages/user/Kyc";
import Maintenance from "./pages/maintenance/Maintenance";
import { FaUserLarge } from "react-icons/fa6";
import Profile from "./pages/user/Profile";
import ReferEarn from "./pages/refer&earn/Refer&Earn";
import { GiTakeMyMoney } from "react-icons/gi";


export const ROUTES=[
    {
        title:"Play",
        key:"home",
        path:"/",
        component:<Home/>,
        show:true,
        icon:<FaPlay size={20} />
    },
    {
        title:"Profile",
        key:"profile",
        path:"/profile",
        component:<Profile/>,
        show:true,
        icon:<FaUserLarge size={20} />
    },
    {
        title:"Home",
        key:"home",
        path:"/battles/:id",
        component:<Battles/>,
        show:false

    },
    {
        title:"Home",
        key:"home",
        path:"/battles/battle/:battleId",
        component:<BattleRoom/>,
        show:false
    },
    {
        title:"Wallet",
        key:"wallet",
        path:"/wallet",
        component:<Wallet/>,
        show:true,
        icon:<IoIosWallet size={20}/>

    },
    {
        title:"Cash",
        key:"cash",
        path:"/add-cash",
        component:<AddCash/>,
        show:false,
        // icon:<IoIosWallet size={20}/>

    },
    {
        title:"History",
        key:"history",
        path:"/wallet-history",
        component:<WalletHistory/>,
        show:true,
        icon:<FaHistory size={20} />
    },
    {
        title:"Refer&Earn",
        key:"refer&earn",
        path:"/refer&earn",
        component:<ReferEarn/>,
        show:true,
        icon:<GiTakeMyMoney size={20} />
    },
    {
        title:"Withdraw",
        key:"withdraw",
        path:"/withdraw",
        component:<Withdraw/>,
        show:false,
        // icon:<FaHistory size={20} />
    },
    {
        title:"Kyc",
        key:"kyc",
        path:"/kyc",
        component:<Kyc/>,
        show:false,
        // icon:<FaHistory size={20} />
    },
    {
        title:"Legal Terms",
        key:"legal",
        path:"/legal-terms",
        component:<LegalTerms/>,
        show:true,
        icon:<FaClipboardList size={20} />
    },
]

export const Auth_ROUTES=[
    {
        title:"Login",
        key:"login",
        path:"/auth",
        component:<Login/>,
    },
       {
        title:"Legal Terms",
        key:"legal",
        path:"/legal-terms",
        component:<LegalTerms/>,
        show:true,
        icon:<FaClipboardList size={20} />
    },
       {
        title:"Main",
        key:"main",
        path:"/maintenance",
        component:<Maintenance/>,
        show:false,
        icon:<FaClipboardList size={20} />
    },
]