import { Button, Card } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { priceInRupees } from '../../helper/helper'
import { useNavigate } from 'react-router-dom'

function Wallet() {
    const {user}=useSelector(s=>s.userReducer)

    const navigate = useNavigate()
  return (
    <div className='flex flex-col gap-5'>

        <Card title="Deposit Cash" >
            <div className='p-2 rounded-md bg-blue-200 text-blue-500 px-4 text-center text-xs'>
            यह Cash Spin & Win एवं Buy की गई चिप्स है इनसे सिर्फ़ गेम खेले जा सकते है ॥ Bank या UPI में निकाला नहीं जा सकता है

            </div>

            <div className='w-full flex flex-col items-center my-4 gap-2'>
                <b className='text-lg'>{priceInRupees(user?.cash)}</b>
                
            </div>
            <Button className='w-full btn-primary' type='primary' onClick={()=>navigate("/add-cash")}>Add</Button>
        </Card>
        <Card title="Withdraw Cash" >
            <div className='p-2 rounded-md bg-blue-200 text-blue-500 px-4 text-center text-xs'>
            यह Cash गेम से जीती हुई एवं रेफरल से कमाई हुई है इन्हें Bank या UPI में निकाला जा सकता है ॥ इन चिप्स से गेम भी खेला जा सकता हैीं जा सकता है

            </div>

            <div className='w-full flex flex-col items-center my-4 gap-2'>
                <b className='text-lg'>{priceInRupees(user?.win)}</b>
            </div>
            <Button className='w-full btn-primary' type='primary' onClick={()=>navigate("/withdraw")}>Withdraw</Button>
        </Card>

    </div>
  )
}

export default Wallet