import React from 'react'
import { useSelector } from 'react-redux'
import { PiWhatsappLogoFill } from "react-icons/pi";

function Support() {
    const {settings}=useSelector(s=>s.userReducer)
    let url = `https://wa.me/+91${settings?.whatsapp_number}`
  return (
    <div className='fixed bottom-7 right-5 bg-white rounded-full p- cursor-pointer bs z-10' onClick={()=>window.open(url,"blank")}>
    <PiWhatsappLogoFill size={50} className=' text-green-600'/> 
 </div>
  )
}

export default Support