import { GET_GAMES } from "../types/games.types";

export const gamesReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_GAMES:
      return {...state,games:payload}
    default:
      return state;
  }
};
