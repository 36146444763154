import React, { useContext, useState } from "react";
import { AppDomain, AppName } from "../../helper/Configs";

function LegalTerms() {
  const [index, setIndex] = useState();
  const [settings, setSettings] = useState();

  const handleClick = (key) => {
    if (index === key) {
      setIndex(0);
    } else {
      setIndex(key);
    }
  };
  return (
    <div className="p-2 ">
      <div className=" rounded-md bg-white outline outline-1 ">
        <div className="tc border-b ">
          <div
            className={`p-3 ${index === 1 && "bg-50 text-400"} transition-all`}
            onClick={() => handleClick(1)}
          >
            Terms & Conditions
          </div>

          {index === 1 && (
            <div className="flex flex-col gap-5 p-3 ">
              <div>
                <p>
                  These <strong>terms and conditions</strong> of use (“Terms”)
                  along with privacy policy (“Privacy Policy”) forms a legally
                  binding agreement (“Agreement”) between You and us (
                  {AppDomain}).
                  <br />
                  <br />
                  Hence, We insist that You read these Terms and Privacy Policy
                  and let Us know if You have any questions regarding the same.
                  We will try Our best to answer Your queries.
                </p>
              </div>

              <div className="flex flex-col gap-2">
                <b>A. Users Approval</b>
                <ul className="px-1">
                  <li>
                    1. Users approve of and accept over Agreement by:
                    <ul className="px-2">
                      <li>(a) Reading all terms and condition</li>
                      <li>(b) Reading all rules of this app</li>
                    </ul>
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    2. Users may accept this Agreement only if:
                    <ul className="px-2">
                      <li>
                        (a) Such User is a natural person, is of the legal age
                        (18 years or older), eligibility and mental capacity to
                        form a binding contract with us.
                      </li>
                      <li>
                        (b) Such User is a not a resident of Tamil Nadu, Andhra
                        Pradesh, Telangana, Assam, Orissa, Sikkim, Nagaland.
                      </li>
                      <li>
                        (c) Such User is a juristic person, is lawfully
                        existing, and has all the authorizations, permits, and
                        allowances to enter into this Agreement and form a
                        binding contract.
                      </li>
                      <li>
                        (d) Such User is not legally barred or restricted from
                        using the App.
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    3. You understand that We want You to not use the App if You
                    do not understand, approve of or accept all the terms
                    specified in this Agreement. Hence, You are requested to
                    read these Terms and Privacy Policy carefully and understand
                    the Agreement before You accept it and agree to be bound by
                    it.
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    4. The Agreement shall govern the relationship of each User
                    with us. However, We may also execute separate written
                    agreements with its Users. In case of conflict between terms
                    of such separate written agreement and this Agreement, the
                    terms of the separate written agreement shall prevail.
                  </li>
                </ul>
              </div>

              <div className="flex flex-col gap-2">
                <b>B. PROVISION OF THE APP</b>
                <ul className="px-1">
                  <li>
                    1. Section 12 of the Public Gambling Act, 1867 provides that
                    games of mere skill are exempt from the application of the
                    Act. The Supreme Court of India and various High Courts in
                    India have opined that a game of mere skill is a game “in
                    which, although the element of chance necessarily cannot be
                    entirely eliminated, success depends principally upon the
                    superior knowledge, training, attention, experience and
                    adroitness of the player. A game of skill is one in which
                    the element of skill predominates over the element of
                    chance.” No penalty can be imposed upon a person for playing
                    such games of skill.
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    2. Users must note that ‘Ludo’ game available for challenge
                    in our platform is ‘Games of Skill’, under Indian law, and
                    that we does not support, endorse or offer to Users ‘games
                    of chance’ for money. While ‘Games of Skill’ do not have a
                    comprehensive definition, they are those games where the
                    impact of a player’s effort and skill on the outcome of a
                    game is higher than the impact of luck and chance.
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    3. It may be noted that States are permitted, by the Indian
                    Constitution, to enact laws regulating betting and gambling
                    in their respective jurisdictions. In furtherance of these
                    powers, various States have enacted anti- gambling
                    legislations. Such legislations are largely in concert with
                    the Public Gambling Act of 1867 (and include the exception
                    of “games of skill”). Where a State legislation on gambling
                    exists, it prevails over the Public Gambling Act of 1867. In
                    this regard, the Assam Game and Betting Act, 1970 and Orissa
                    (Prevention of) Gambling Act, 1955 and Telangana State
                    Gaming (Amendment) Ordinance and High Court Judgment in
                    Gujarat, 2017 prohibits games with money stakes and also
                    does not create an exception for games of skill. Therefore,
                    currently, residents of Assam, Odisha, Telangana and Gujarat
                    are not permitted to play on our site.
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    4. The games rules are clearly defined on this platform and
                    Users are encouraged to read, understand and follow these
                    rules to be successful in these games.
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    5. The games on our platform are ‘Games of Skills’, such
                    that the outcome / success in the games is directly
                    dependent on the User’s effort, performance and skill. By
                    choosing how to play, the actions of Users shall have direct
                    impact on the game.
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    6. Every game will have some elements of chance, but in the
                    form of challenges / obstacles that a User would be able to
                    overcome using his/her skills and knowledge of the game.
                    Elements of luck are present in every game to add thrill and
                    excitement, but no two attempts at a game are identical so
                    Users must use their skills in order to be successful
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    7. Since the games available on our platform can be won
                    through Users’ skills and such skills may be enhanced with
                    practice and experience, the performance of a User may
                    improve with time and practice.
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    8. Certain games may have pre-determined outcomes (Ludo),
                    and these outcomes are achievable by Users using their
                    skills.
                  </li>
                </ul>
              </div>

              <div className="flex flex-col gap-2">
                <b>C. GAME RULES</b>
                <ul className="px-1">
                  <li>
                    1. Player who sets a challenge will share a room id/room
                    code with his/her opponent.
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    2. On winning both players have to update there results in
                    following manner:
                    <ul className="px-2">
                      <li>
                        (a) if you have won, select ‘I Won’ option and upload
                        winning screenshot of the game.
                      </li>
                      <li>(b) if you have lost, select ‘I Lost’ option.</li>
                      <li>
                        (c) if match is not started and both parties doesn't
                        want to play, select ‘Cancel’ option.
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    3. User must have to record every game, and if any player is
                    hacking or cheating a game, contact support.
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    4. If game is not started, if you haven't played a single
                    move yourself, please show the recording of game to support.
                    Game will be cancelled only if you have recording.
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    5. If you don't have any proof against player cheating and
                    error in game, you will be considered as lost.
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    6. If you have not moved a single pawn or no pawn is open at
                    home, your game will be cancelled.
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    7. If your opponent leaves match purposely in starting or
                    initial game, and opponent doesn't have any valid proof for
                    cancellation, you will be awarded win.
                  </li>
                </ul>
              </div>

              <div className="flex flex-col gap-2">
                <b>D. DEPOSIT / WITHDRAWAL</b>
                <ul className="px-1">
                  <li>
                    1. Players can deposit their balance in{" "}
                    <a href="/addcash" className="underline font-semibold">
                      Add Cash
                    </a>{" "}
                    section.
                    <p className=" mt-4">
                      Important:- If we detect any type of suspecious
                      transaction/activity in your account, in such cases we
                      have rights to Block your account temporarily. Kindly
                      contact Admins in such cases and provided the needed
                      details to Un-block your account.
                    </p>
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    2. Player can take withdrawal by setting a Sell Request on
                    your app.
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    3. Deposit and withdrawal requests are completed by support
                    at any time.
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    4. Any wrong payment detail given by you, will not be
                    considered in refund.
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    5. Once a withdrawal is done, you don't have any authority
                    to raise any query.
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    6. If withdrawal request go on pending, user must have to
                    wait for 1-5 days.
                  </li>
                </ul>
              </div>

              <div className="flex flex-col gap-2">
                <b>E. PENALITY FOR WRONG UPDATES</b>
                <ul className="px-1">
                  <li>
                    1. If you put the wrong update on any match, you will be
                    charged penality of:
                    <ul className="px-2">
                      <li>
                        (a) if your game is below 1000, penalty will be 10%.
                      </li>
                      <li>
                        (b) if your game is above 1000 and below 5000, penality
                        will be 50 flat.
                      </li>
                      <li>
                        (c) if your game is above 5000 and below 15000, penality
                        will be 100 flat.
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    2. If you don't update result after losing, you will be
                    charged penality of 25 flat.
                  </li>
                </ul>
                <ul className="px-1">
                  <li>
                    3. Players can have only single account in case multiple
                    accounts found, We have authority to ban those account
                    permanently & add penalty
                  </li>
                </ul>
              </div>

              <div className="flex flex-col gap-2">
                <b>F. COMMISSION CHARGES</b>

                <ul className="px-1">
                  <li>
                    1. Net {settings?.admin_commision}% commission (after
                    referral money deduction) will be charged on challenge
                    amount.
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>

        {/* can p */}
        <div className="cr border-b ">
          <div
            className={`p-3 ${index === 2 && "bg-50 text-400"} transition-all`}
            onClick={() => handleClick(2)}
          >
            Cancellation & Refund Policy
          </div>

          {index === 2 && (
            <div className="flex flex-col gap-4 p-3 ">
              <div className="flex flex-col gap-5">
                <b>Users Approval</b>
                <ul>
                  <li>
                    Thanks for being connected with {AppName}. If you are not
                    entirely satisfied with your subscription, we are here to
                    help.
                  </li>
                </ul>
              </div>
              <div className="flex flex-col gap-1">
                <b>Refunds Process</b>
                <ul>
                  <li className="flex flex-col gap-5">
                    <p>
                      Once we receive your Refund request, we will inspect it
                      and notify you on the status of your refund.
                    </p>
                    <p>
                      If your refund request is approved, we will initiate a
                      refund to your credit card (or original method of payment)
                      within 7 working days. You will receive the credit within
                      a certain amount of days, depending on your card issuer's
                      policies.
                    </p>
                    <p>
                      In case of unforeseen technical glitch, {AppName} would
                      refund subscription upon reviewing the complaint. Final
                      decision lies with the company.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>

        {/* privacy pollicy */}
        <div className="cr border-b ">
          <div
            className={`p-3 ${index === 3 && "bg-50 text-400"} transition-all`}
            onClick={() => handleClick(3)}
          >
            Privacy Policy
          </div>

          {index === 3 && (
            <div className="flex flex-col gap-4 p-3 ">
              <div className="flex flex-col gap-5">
                <b>Introduction</b>
                <ul>
                  <li>
                    This document explains how {AppName} (https://{AppDomain})
                    collects, processes, stores and/or shares any personal data
                    and/or information from users (jointly referred to as
                    “Information”). By accessing and/or using the Services you
                    consent the collection, transfer, manipulation, storage,
                    disclosure and other uses of your information as described
                    in this Privacy Policy. If you have any concerns about
                    providing data, or having it used in any manner permitted in
                    this Privacy Policy, you should not use the Services. As set
                    out in the terms and conditions relating the Services (the
                    “Terms”), you must be at least at legal age (minimum age of
                    18 years) to access and/or use the Services, or if legally
                    possible, to access with your legal guardian consent, due
                    authorization and agreement with these Privacy Policy.
                  </li>
                </ul>
              </div>
              <div className="flex flex-col gap-1">
                <b>The Information collected:</b>
                <ul>
                  <li className="flex flex-col gap-5">
                    <p>
                      <b>{AppName} </b>
                      and/or third parties, including but not limited to
                      business partners, advertising networks, analytics or
                      search information providers, may collect and process the
                      following data about you: information that you provide
                      when you fill in forms when accessing and/or using the
                      Services, or when you create an account within the
                      Services; details of your use of the Services and the
                      resources that you access; the correspondence between you
                      and {AppName} and any interactions, or with other users on
                      the Service, the channels and/or any social media related;
                      from third parties who hold data about you and who agree
                      to share this data with us; and information collected via
                      cookies and other similar technologies and/or other
                      mechanisms, as explained further below.
                    </p>
                    <p>
                      <b>Personal Data:</b>While using Our Service, We may ask
                      You to provide Us with certain personally identifiable
                      information that can be used to contact or identify You.
                      Personally identifiable information may include, but is
                      not limited to:
                    </p>
                    <p>
                      <b>* Email address</b>
                    </p>
                    <p>
                      <b>* First name and last name</b>
                    </p>
                    <p>
                      <b>* Address, State, Province, ZIP/Postal code, City</b>
                    </p>
                    <p>
                      1.Cookies: {AppName} uses cookies and other similar
                      technologies in the Services. These technologies operate
                      either by placing a small file which stores some
                      information on your computer or mobile device; and/or by
                      accessing information on your device. {AppName} uses
                      cookies and similar technologies to recognize you and your
                      device, for example by identifying your IP address; to
                      allow the Services to interact with a third party social
                      network or platform where you have chosen to allow such
                      interaction; to allow payment processes when you submit
                      payment instructions; to enable {AppName} and third
                      parties to provide you with more customized services; and
                      to collect data such as your device’s model, operating
                      system and screen size, other applications installed on
                      your device, and information about how you use the
                      Services. By accessing and/or using the Services you
                      consent the use of cookies and similar technologies in
                      accordance with this Privacy Policy. You can disable
                      cookies through your web or mobile device browser settings
                      but some features of the Services may not function
                      properly. Alternatively, if you do not wish such data
                      collection as described in this section, you should stop
                      using the Services.
                    </p>
                    <p>
                      II. Ads: Advertisers on mobile devices sometimes use
                      advertising identifiers to enable and optimize their
                      advertising, to deliver tailor ads related to your
                      interests (Interest-Based Advertising or IBA). These
                      identifiers are non-permanent, non-personal, device
                      identifiers. {AppName} and/or third parties may use your
                      device’s advertising identifier and other information
                      associated with it, to deliver ads that relate to your
                      interests and to improve and measure the effectiveness of
                      ad campaigns.
                    </p>
                    <p>
                      III. Payment information: If you make a purchase in the
                      Services, {AppName} may collect the billing and financial
                      information necessary to process the charges; and/or do so
                      on behalf of the relevant payment service providers.
                      Purchases of third party services are subject to the
                      policies applicable to such provider.
                    </p>
                    <p>
                      IV. Customer Support Correspondence: When you ask for
                      assistance from {AppName} customer support, the contact
                      information you provide will be collected, as well as
                      information about your game play or activity on the
                      Service, your user ID number, and the correspondence and
                      any information contained within. If available through the
                      Services, you may provide {AppName} with profile
                      information to make public, such as a short biography,
                      location, website, cell phone, a picture, information to
                      customize your account, etc. Such contact information may
                      be used to send you information about the Services or
                      related information. You may use your account settings to
                      unsubscribe from notifications from {AppName} and/or from
                      other users and find users you know. You may also
                      unsubscribe by following the instructions contained within
                      the notification or the instructions on the Service. Also{" "}
                      {AppName} may use your contact information to help others
                      find your account, including through third-party services
                      and client applications. Providing any additional
                      information described in this section is entirely
                      optional.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col gap-1">
                <b>Use of Information:</b>
                <ul>
                  <li className="flex flex-col gap-5">
                    <p>
                      When you create or configure an account in the Services,
                      you provide some personal information, such as your name,
                      username, password, email address and any other
                      information as required time to time. Some of this
                      information, for example, your name and/or username, may
                      be listed publicly on the Services, including on your
                      profile page and in search results. You agree that{" "}
                      {AppName} and/or third parties on its behalf and/or its
                      partners may use the Information for the purposes of
                      contacting you as part of customer support; to send you
                      updates or information about the Services; managing your
                      account and relationship with the Service and improving
                      your experience when you use it, improving the Services,
                      research, surveying, and engaging with you, for example by
                      sending you communications for these purposes; marketing
                      and promotion of the Services or products; to personalize
                      and optimize the Services, promotional content and/or
                      advertising; to create reports, analysis or similar
                      services for the purposes of research or business
                      intelligence.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col gap-1">
                <b>Information sharing and disclosure:</b>
                <ul>
                  <li className="flex flex-col gap-5">
                    <p>
                      {AppName} will only share your data with third parties
                      according to the Privacy Policy, as reasonably necessary
                      in order to provide the Services, for example, by
                      providing Information to suppliers that {AppName} may use
                      to fulfill the Services; where it is necessary to carry
                      out your instructions, for example, to process a payment
                      instruction your Information has to be provided to the
                      payment processors; where your data is on an anonymous and
                      aggregated basis, meaning you could not be personally
                      identified from it; for the delivery of Interest-Based
                      Advertising in the manner set out on this Privacy Policy;
                      when you submit information as part of a competition or
                      otherwise interact with any channel or social media, the
                      information you submitted may be published; as it`s
                      reasonably believed is permitted by law or regulation; in
                      order to comply with any legal obligation, or in order to
                      enforce or apply the Terms, this Privacy Policy and/or any
                      other agreement with you; or to protect the rights and/or
                      property of {AppName} or third-party´s rights and/or
                      property. {AppName} may share or disclose your non-private
                      information, such as public user profile information,
                      public messages, e-mail, etc., or share or disclose your
                      information in an anonymous or aggregated basis in a
                      manner that does not allow your personal identification.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col gap-1">
                <b>Term:</b>
                <ul>
                  <li className="flex flex-col gap-5">
                    <p>
                      {AppName} may retain the Information for as long as is
                      necessary to fulfill the purposes for which it was
                      collected or as needed to provide the Services, even after
                      you have discontinued or deleted any account, or after the
                      end of the provision of the Services, if retention of such
                      Information is reasonably necessary to comply with legal
                      obligations, meet regulatory requirements, resolve
                      disputes between users, prevent fraud, or any other use.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col gap-1">
                <b>Protection of Information:</b>
                <ul>
                  <li className="flex flex-col gap-5">
                    <p>
                      {AppName} maintains appropriate technical and physical
                      safeguards to protect Information against accidental or
                      unlawful destruction or loss, alteration, unauthorized
                      disclosures or access, and any other unlawful forms of
                      processing of the data in its possession. However,{" "}
                      {AppName} does not guarantee that Information will not be
                      accessed, disclosed, altered or destroyed by breach of any
                      of the abovementioned safeguards. Information may be
                      transferred to and/or stored at worldwide destinations.{" "}
                      {AppName} takes all steps reasonably necessary to ensure
                      that Information is treated securely and in accordance
                      with this Privacy Policy. In the event that {AppName} is
                      involved in a bankruptcy, merger, acquisition,
                      reorganization or sale of assets, your information may be
                      sold or transferred as part of that transaction. The
                      undertakings in this Privacy Policy shall apply to the
                      Information as transferred to the new entity.
                    </p>
                    <p>
                      Not with standing anything to the contrary in this Policy,
                      {AppName} may preserve or disclose your information to the
                      extent reasonably necessary to comply with a law,
                      regulation or legal request; to protect the safety of any
                      person; to address fraud, security or technical issues; or
                      to protect {AppName}'s rights or property. However,
                      nothing in this Privacy Policy is intended to limit any
                      legal defenses or objections that you may have to a third
                      party’s, including a government’s request to disclose your
                      information. We wants to make sure that your Information
                      is accurate and up to date. You may ask to modify, correct
                      or remove information with the tools and account settings
                      of the Service, or otherwise by contacting {AppName} at
                      support@{AppDomain}.
                    </p>
                    <p>
                      If any court or other competent authority finds any of
                      this Privacy Policy to be invalid or unenforceable, the
                      other terms of this Privacy Policy will not be affected.
                      This Privacy Policy is governed by and interpreted in
                      accordance with the laws of Nagaland State Government as
                      well as Republic of India. Any dispute arising in
                      connection with this Privacy Policy will be subject to the
                      exclusive jurisdiction of the courts located in the city
                      of Jaipur/Rajasthan – India. You consent the jurisdiction
                      and venue in such courts and waive any objection as to
                      inconvenient forum {AppName} may revise or amend this
                      Privacy Policy from time to time.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>

        {/* about us */}
        <div className="cr border-b ">
          <div
            className={`p-3 ${index === 4 && "bg-50 text-400"} transition-all`}
            onClick={() => handleClick(4)}
          >
            About Us
          </div>

          {index === 4 && (
            <div className="flex flex-col gap-4 p-3 ">
              <div className="flex flex-col gap-5">
                <ul>
                  <li>
                    We are a real-money online entertainment product owned and
                    operated by Allinone Hax. We are an HTML5
                    entertainment-publishing company and our mission is to make
                    accessing entertainment fast and easy by removing the
                    friction of app-installs. {AppName} is a skill-based
                    real-money gaming platform accessible only for our users in
                    India. It is accessible on https://{AppDomain}. On Ludo
                    Players, users can compete for real cash in Tournaments and
                    Battles. They can encash their winnings via popular options
                    such as Paytm Wallet, UPI or Phonepe.
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>

        {/* Contact us */}
        <div className="cr border-b ">
          <div
            className={`p-3 ${index === 5 && "bg-50 text-400"} transition-all`}
            onClick={() => handleClick(5)}
          >
            Contact Us
          </div>

          {index === 5 && (
            <div className="flex flex-col gap-4 p-3 ">
              <div className="flex flex-col gap-5">
                <ul>
                  <li>support@{AppDomain}</li>
                </ul>
                <ul>
                  <li>
                    <a href={AppDomain} className="underline">
                      www.{AppDomain}
                    </a>
                  </li>
                </ul>
                <ul>
                  <li>
                    Merchant Legal entity name: Unique Services <br />
                    Registered Address: Jaipur, Rajasthan, PIN: 303501 <br />
                    Operational Address:Jaipur, Rajasthan, PIN: 303501 <br />
                    {/* Telephone No: 9414326597 <br />
                      E-Mail ID: babaplayers123@gmail.com */}
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LegalTerms;
