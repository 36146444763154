import { get } from "../../helper/api";
import { APPLOADING, GET_SETTINGS, GET_USER } from "../types/user.types";

export const GetUser = () => {
  return async (dispatch) => {
    const res = await get("/auth/get-user");
    if (res?.success) {
      dispatch({
        type: GET_USER,
        payload: res,
      });
    }
    dispatch({
        type: APPLOADING,
        payload: false,
      });

    return;
  };
};
export const GetSettings = () => {
  return async (dispatch) => {
    const res = await get("/app/get-settings");
    if (res?.success) {
      dispatch({
        type: GET_SETTINGS,
        payload: res?.settings,
      });
    }
    return;
  };
};
