import { APPLOADING, GET_SETTINGS, GET_USER } from "../types/user.types";




export const userReducer = (
  state = {
    isLoggedIn: false,
    AppLoading:true
  },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case APPLOADING:
        return {...state,AppLoading:payload}
    case GET_USER:
        return {...state,user:payload?.user,metrics:payload?.metrics}
        case GET_SETTINGS:
          return {...state,settings:payload}
    default:
      return state;
  }
};
