import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import AppStates from "./context/AppStates";
import { Provider } from "react-redux";
import { STORE } from "./redux/Store";
import { ConfigProvider } from "antd";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ConfigProvider
      theme={{
        components: {
          Button: {
            defaultHoverColor: "",
            defaultHoverBg: "",
            defaultActiveBg: "",
            defaultHoverBorderColor: "",
            defaultActiveBorderColor: "",
            defaultActiveColor: "",
          },
          Card: {
            headerHeightSM: 30,
          },
        },
        token: {
          colorPrimary: "#12609b",
        },
      }}
    >
      <Provider store={STORE}>
        <AppStates>
          <App />
        </AppStates>
      </Provider>
    </ConfigProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
