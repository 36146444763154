import { Button, Card, Input, Typography, message } from "antd";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import { FaWhatsapp } from "react-icons/fa6";
import { AppName } from "../../helper/Configs";

function ReferEarn() {
  const { user, settings } = useSelector((s) => s.userReducer);

  let url =
    "https://wa.me/?" +
    "&text=" +
    "Url:- " +
    `${window.location.origin}/auth?referBy=${user?.key_id}` +
    "%0a" +
    "Referral Code:- " +
    user?.key_id;
  return (
    <Card title="Refer & Earn">
      <img
        src="https://moru.com.np/company/wp-content/uploads/2021/03/Blog_refer-Earn.jpg"
        alt="refer"
      />

      <div className="mt-4 flex flex-col gap-2">
        <Typography.Text>Referral Code:</Typography.Text>
        <Input value={user?.key_id} className="input-primary" readOnly />
      </div>
      <div className="mt-2 grid grid-cols-2 gap-2">
        <CopyToClipboard
          text={user?.key_id}
          onCopy={() => message.success("Code Copied")}
        >
          <Button className="btn-primary">Copy Code</Button>
        </CopyToClipboard>
        <CopyToClipboard
          text={window.location.origin + `/auth?referBy=${user?.key_id}`}
          onCopy={() => message.success("Referral link copied")}
        >
          <Button className="btn-primary" type="primary">
            Copy Link
          </Button>
        </CopyToClipboard>
      </div>
      <Button
        className="btn-primary bg-green-500 w-full mt-2 flex items-center justify-center gap-2 text-white"
        onClick={() => window.open(url)}
      >
        <FaWhatsapp size={20} />
        Share
      </Button>
      <div className="p-2 mt-4 bg-white">
        <h1>
          <b>Refer & Earn Rules </b>{" "}
        </h1>
        <div className="flex flex-col gap-2 p-5">
          <div className="flex flex-col  gap-2 p-2 rounded ">
            <p>
              When your friend signs up on {AppName} from your referral link,
            </p>
            <p className="text-green-500">
              You get {settings?.refer_commission}% Commission on your
              referral's winnings.
            </p>
          </div>
          <div className="flex flex-col  gap-2 p-2  rounded">
            <p>Suppose your referral plays a battle for ₹10000 Cash,</p>
            <p className="text-green-500">
              You get ₹{(settings?.refer_commission / 100) * 10000} Cash
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default ReferEarn;
