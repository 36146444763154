import logo from "./logo.svg";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import { Auth_ROUTES, ROUTES } from "./Routes";
import AppLayout from "./layout/AppLayout";
import { useSelector } from "react-redux";
import Loader from "./components/Loader";
import { useEffect } from "react";
import { LoadApp } from "./redux/actions/rootActions";
// import { generateToken, messaging } from "./firebase/firebase";
import { onMessage } from "firebase/messaging";
import Maintenance from "./pages/maintenance/Maintenance";

function App() {
const {AppLoading,user,settings}=useSelector(s=>s.userReducer)

useEffect(()=>{
LoadApp()
// generateToken()
// onMessage(messaging,(payload)=>{
//   console.log(payload);
// })
},[])


if(AppLoading||!settings){
  return <Loader/>
}
if(settings&&settings?.maintenance){
  return <Maintenance/>
}
  return (
    <AppLayout>
      {!AppLoading&&settings&&user?<Routes>
        {ROUTES.map((r, i) => {
          return <Route path={r?.path} key={i} element={r?.component} />;
        })}
        <Route path="*" element={<Navigate to={"/"}/>}/>
      </Routes>:
      <Routes>
      {Auth_ROUTES.map((r, i) => {
        return <Route path={r?.path} key={i} element={r?.component} />;
      })}
      <Route path="*" element={<Navigate to={"/auth"}/>}/>
    </Routes>
      }
    </AppLayout>
  );
}

export default App;
