import React from 'react'

function Maintenance() {
  return (
    <div className='flex flex-col  items-center w-full h-screen'>
        <img src="https://img.freepik.com/vector-premium/icono-logotipo-mantenimiento_617585-1962.jpg" alt="maintenance" />
        <b>App Under Maintenance</b>
    </div>
  )
}

export default Maintenance