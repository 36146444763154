import React, { useContext } from "react";
import AppContext from "../context/context";
import { useSelector } from "react-redux";
import { IoIosWallet } from "react-icons/io";
import { TiThMenu } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import CommissionBadge from "./CommissionBadge";
import { Typography } from "antd";
import { AppName } from "../helper/Configs";

function Header() {
  const { sideBar, setSideBar } = useContext(AppContext);
  const { user } = useSelector((s) => s.userReducer);
  const navigate = useNavigate();
  return (
    <div className="sticky top-0 left-0 z-10">
      <CommissionBadge />
      <div className="w-full bg-PRIMARY shadow h-[70px] flex text-white  p-4">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-2">
            <TiThMenu size={30} onClick={() => setSideBar(true)} />
            <div
              className="flex flex-col items-center w-[50px] rounded-full overflow-hidden"
              onClick={() => navigate("/")}
            >
              <img
                src={require("../assets/logo.png")}
                alt="logo"
                className="  object-cover scale-105 w-full h-full"
              />
            </div>
          </div>

          {user && (
            <div
              className="border border-white text-white flex items-center gap-2 p-2 rounded-md"
              onClick={() => navigate("/wallet")}
            >
              <IoIosWallet size={25} className="text-white" />

              <b>{(Number(user?.win) + Number(user?.cash))?.toFixed(1)}</b>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
