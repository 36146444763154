import { Button, Card, Input, Typography } from "antd";
import React from "react";
import { DEFAULT_USER_IMAGE } from "../../helper/Configs";
import { useSelector } from "react-redux";
import WarningTag from "../../components/WarningTag";
import { priceInRupees } from "../../helper/helper";
import UpdateUserName from "./UpdateUserName";

function Profile() {
    const {user,metrics}=useSelector(s=>s.userReducer)

  return (
    <>
      <Card title={ <div className="flex items-center justify-center"><Typography.Text>Profile</Typography.Text></div> }>
        <div>

  
        <div className="flex items-center justify-center">
            <img src={DEFAULT_USER_IMAGE} alt="user"  className="w-[70px] h-[70px] rounded-full"/>

        </div>
        <div className="flex flex-col mb-2">
            <Typography.Text>UserName</Typography.Text>
            <div className="flex items-center gap-2">
            <Input readOnly value={user?.username||user?.name} disabled />
<UpdateUserName/>
            </div>
        </div>
        <div className="flex flex-col mb-2">
            <Typography.Text>Mobile Number</Typography.Text>
            <Input readOnly value={user?.mobile} disabled/>

        </div>
       {user?.refferBy&& <div className="flex flex-col mb-2">
            <Typography.Text>Refer by</Typography.Text>
            <Input readOnly value={user?.refferBy} disabled/>

        </div>}
        {!user?.kyc_verified&&<WarningTag kyc/>}
        </div>
      </Card>

      <Card title={ <div className="flex items-center justify-center"><Typography.Text>Metrics</Typography.Text></div> } className="mt-4" styles={{body:{padding:"10px"}}}>
<div className="grid grid-cols-2 gap-2" >
    <Card title="Played" type="inner" styles={{body:{padding:"10px"}}} className="font-semibold text-md">
        {metrics?.played}
    </Card>
    <Card title="Win" type="inner" styles={{body:{padding:"10px"}}} className="font-semibold text-md">
        {priceInRupees(metrics?.won)}
    </Card>
    <Card title="Refer Earning" type="inner" styles={{body:{padding:"10px"}}} className="font-semibold text-md">
        {priceInRupees(metrics?.referEarn)}
    </Card>
    <Card title="Total Referrals" type="inner" styles={{body:{padding:"10px"}}} className="font-semibold text-md">
        {metrics?.totalReferrals}
    </Card>
    <Card title="Penalty" type="inner" styles={{body:{padding:"10px"}}} className="font-semibold text-md">
        {priceInRupees(metrics?.penalty)}
    </Card>

</div>

      </Card>
      <Button className="mt-4 w-full btn-primary bg-red-400 text-white" onClick={()=>{
            localStorage.clear()
            window.location.reload()
          }}>Logout</Button>
    </>
  );
}

export default Profile;
