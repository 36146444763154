import { Button, Card, Form, Input, Typography, message } from "antd";
import React, { useState } from "react";
import WarningTag from "../../components/WarningTag";
import { useSelector } from "react-redux";
import { priceInRupees } from "../../helper/helper";
import { post } from "../../helper/api";
import { STORE } from "../../redux/Store";
import { GetUser } from "../../redux/actions/user.action";

const tabList = [
  {
    key: "upi",
    tab: "Upi",
  },
  {
    key: "bank",
    tab: "Bank",
  },
];

function Withdraw() {
  const { user, settings } = useSelector((s) => s.userReducer);
  const [mode, setMode] = useState("upi");
  const [loading, setLoading] = useState(false);

  const onSubmit = async (vals) => {
    vals.mode = mode;
    var amount = vals?.amount;
    if (!amount) {
      return message.error("Enter amount!");
    }
    if (isNaN(amount)) {
      return message.error("Amount should be a number");
    }
    amount = Number(vals?.amount);
    if (amount < settings?.min_withdraw) {
      return message.error(
        `Enter minimum amount ${priceInRupees(settings?.min_withdraw)}`
      );
    }
    if (amount > settings?.max_withdraw) {
      return message.error(
        `Enter maximum amount ${priceInRupees(settings?.max_withdraw)}`
      );
    }
    if (!user?.kyc_verified) {
      return message.error("First complete kyc to withdraw money ");
    }
    if (amount > user?.win) {
      return message.error("Low Balance");
    }
    setLoading(true);
    const res = await post("/wallet/withdraw", vals);
    if (res?.success) {
      message.success(res?.message);
      STORE.dispatch(GetUser());
    } else {
      message.error(res?.message);
    }
    setLoading(false);
  };
  return (
    <>
      {!user?.kyc_verified && <WarningTag kyc />}
      <WarningTag text={"Note:- Maximum 3 withdraw request per/day allowed"}/>
      <div>
        <Card
          title="Withdraw"
          activeTabKey={mode}
          onTabChange={(e) => setMode(e)}
          tabList={tabList}
          loading ={loading}
        >
          <Form layout="vertical" onFinish={onSubmit}>
            <Form.Item
              label="Account Holder Name"
              name={"ac_name"}
              rules={[{ required: true, message: "Holder name required" }]}
            >
              <Input />
            </Form.Item>

            {mode === "upi" && (
              <>
                <Form.Item
                  label="Upi ID"
                  name={"upiId"}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </>
            )}
            {mode === "bank" && (
              <>
                <Form.Item
                  label="Account Number"
                  name={"ac_number"}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="IFSC Code"
                  name={"ifsc"}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </>
            )}

            <Form.Item
              label="Amount"
              name={"amount"}
              help={`Min: ${priceInRupees(
                settings?.min_withdraw
              )}, Max: ${priceInRupees(settings?.max_withdraw)}`}
            >
              <Input
                className="input-primary"
                placeholder="100"
                inputMode="numeric"
              />
            </Form.Item>

            <Button
              type="primary"
              className="btn-primary w-full mt-3"
              htmlType="submit"
            >
              Proceed
            </Button>
          </Form>
        </Card>
      </div>
    </>
  );
}

export default Withdraw;
