import { Button, Card, Spin, Typography, message } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import socket from "../../../helper/socket";
import { BATTLE_STATUS, SOCKET_EMITS } from "../../../helper/Constants";
import { DEFAULT_USER_IMAGE } from "../../../helper/Configs";
import { useNavigate } from "react-router-dom";
import { BsLightningChargeFill } from "react-icons/bs";
import { GiMoneyStack } from "react-icons/gi";

function RunningCard({ battle }) {
  const { user } = useSelector((s) => s.userReducer);
  const rootUser = user?.key_id === battle?.createrId;
  const navigate = useNavigate();

  const onView = () => {

      navigate(`/battles/battle/${battle?._id}`);
 
  };

  return (
    <Card
      className=" rounded-lg bg-white flex flex-col"
     
    size="small"
      title={
        <div className="flex items-center justify-between h-full p-1">
          <p className="text-xs font-thin flex items-center gap-1">
            Playing for{" "}
            <strong className="flex items-center gap-1">
              <GiMoneyStack className="text-green-500" size={15} />
              {battle?.amount}
            </strong>
          </p>
          {user?.key_id === battle?.createrId ||
          user?.key_id === battle?.joinerId ? (
            <Button
              onClick={onView}
              type="default"
              className={`${battle?.status===BATTLE_STATUS.pending?"bg-yellow-500":"bg-slate-500"} text-white h-[30px] text-xs`}
            >
              View
            </Button>
          ) : null }
           <p className="text-xs font-thin flex items-center gap-1">
         Prize{" "}
          <strong className="flex items-center gap-1">
            <GiMoneyStack className="text-green-500" size={15} />
            {battle?.prize}
          </strong>
        </p>
        </div>
      }
    >
      <div className="grid grid-cols-3 gap-5">
        <div className="flex flex-col gap-2 items-center">
          <img
            src={DEFAULT_USER_IMAGE}
            alt="userImage"
            className="w-[30px] h-[30px] rounded-full"
          />
          <b className="text-xs ">{battle?.creater_name||battle?.createrId}</b>
        </div>
        
        <div className="flex flex-col gap-2 items-center ">
          <BsLightningChargeFill size={10} className="text-red-600" />
          <Typography.Text className="font-bold text-red-500">
            V/S
          </Typography.Text>
        </div>

        <div className="flex flex-col gap-2 items-center">
          <img
            src={DEFAULT_USER_IMAGE}
            alt="userImage"
            className="w-[30px] h-[30px] rounded-full"
          />
          <b className="text-xs ">{battle?.joiner_name||battle?.joinerId}</b>
        </div>
      </div>
    </Card>
  );
}

export default RunningCard;
