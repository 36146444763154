import { Button, Card, Form, Input, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { get, post, uploadImg, uploadImgKyc } from "../../helper/api";
import { KYC_STATUS } from "../../helper/Constants";
import WarningTag from "../../components/WarningTag";

function Kyc() {
  const { user } = useSelector((s) => s.userReducer);
  const [form] = useForm();
  const [file, setFile] = useState();
  const [fileBack, setFileBack] = useState();
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [kyc, setKyc] = useState(false);

  const handleImgChange = (e) => {
    try {
      setFile(e.target.files[0]);
    } catch (error) {}
  };
  const handleImgChange2 = (e) => {
    try {
      setFileBack(e.target.files[0]);
    } catch (error) {}
  };

  const onSubmit = async (values) => {
    if (!file) {
      return message.error("Select document front side photo");
    }else if(!fileBack){
      return message.error("Select document back side photo");
    }

    setLoading(true);
    if (file&&fileBack) {
      const uploadIm = await uploadImgKyc(file);
      const uploadImB = await uploadImgKyc(fileBack);
      if (uploadIm?.success&&uploadImB?.success) {
        values.doc_photo = uploadIm?.url;
        values.doc_photo_back = uploadImB?.url;
        const res = await post("/app/update-kyc", values);
        if (res?.success) {
          message.success("Kyc Requested");
          getKyc()
        }else{
            message.error(res?.message)
        }
        setLoading(false);
      }
    }
  };
  
  const getKyc = async()=>{
    const res = await get("/app/get-kyc")
    if(res?.success){
        setKyc(res?.kyc)
    }
    setFetching(false)
  }
  
  useEffect(()=>{
    getKyc()
  },[])

  if(fetching){
    return <Card loading></Card>
  }

  if(kyc&&!fetching&&kyc?.status!==KYC_STATUS.declined){
    return <WarningTag text={`Your Kyc request is ${kyc?.status}`}/>
    
  }
  

  return (
    <Card title="Complete Kyc">
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item label="Name" name={"doc_name"}>
          <Input className="input-primary" placeholder="Name on document" />
        </Form.Item>
        <Form.Item label="Document Number" name={"doc_number"}>
          <Input className="input-primary" placeholder="Id proof number" />
        </Form.Item>
        <Form.Item label="Document Image (Front Side)">
          <Input type="file" onChange={handleImgChange} />
        </Form.Item>
        <Form.Item label="Document Image (Back Side)">
          <Input type="file" onChange={handleImgChange2} />
        </Form.Item>
        <Button htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form>
    </Card>
  );
}

export default Kyc;
