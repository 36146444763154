import { Card, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import WarningTag from "../../components/WarningTag";

function Home() {
  const { games } = useSelector((s) => s.gamesReducer);
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-5">
      <WarningTag
        text={
          " Notice:- पॉपुलर का रूम कोड डालने पर यूजर का बैलेंस जीरो कर दिया जायेगा 🙏 (User's balance will be reduced to zero on entering Popular's room code)"
        }
      />
      <h2 className="font-bold text-lg">Games</h2>

      <div className="grid grid-cols-2 gap-4">
        {games?.map((g, i) => {
          return (
            <div
              className={`shadow rounded-lg overflow-hidden relative `}
              key={i}
              onClick={() => {
                if (g?.active) {
                  navigate(`/battles/${g?._id}`);
                } else {
                  message.error("Game not active yet");
                }
              }}
            >
              <img
                src={g?.image}
                alt="gameImage"
                className="w-full h-full object-cover"
              />
              <div className="absolute bottom-0 left-0 bg-slate-900 p-2 w-full text-white text-center font-semibold">
                {g?.active
                  ? `${g?.title} ${g?.min} - ${g?.max}`
                  : `Comming Soon..`}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Home;
