import { Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";

function CommissionBadge() {
  const { settings } = useSelector((s) => s.userReducer);
  if(!settings){
    return
  }
  return (
    <div className="bg-red-500 p-1 text-center">
      <Typography.Text className="text-white text-center text-xs">
        Admin Commission: {settings?.admin_commission - settings?.refer_commission}%
       & Referral Commission: {settings?.refer_commission}% 
      </Typography.Text>
    </div>
  );
}

export default CommissionBadge;
