import { Button } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function WarningTag({text,kyc,...rest}) {
  const navigate = useNavigate()

  if(kyc){
   return( <div className='rounded-md bg-red-100 text-red-500 text-center p-4 w-full text-xs mb-4'>
  <div className="flex items-center justify-between" {...rest}>
    <p className="text-[14px]">Complete Your KYC !</p>
    <Button type="text" className="bg-red-500 text-white" onClick={()=>navigate("/kyc")}>Complete</Button>

  </div>
</div>)
  
  }
  return (
    <div className='rounded-md bg-red-100 text-red-500 text-center p-4 w-full text-xs mb-4' {...rest}>
        {text}
    </div>
  )
}

export default WarningTag