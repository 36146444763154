import React, { useContext, useEffect, useState } from "react";
import AppContext from "../context/context";
import { IoCloseOutline } from "react-icons/io5";
import { ROUTES } from "../Routes";
import { Link } from "react-router-dom";
import { Button, Typography } from "antd";
import { useSelector } from "react-redux";
import { MdInstallMobile } from "react-icons/md";

function SideBar() {
  const { sideBar, setSideBar, onClick } = useContext(AppContext);
  const { user } = useSelector((s) => s.userReducer);

  return (
    <div
      className={`${
        sideBar ? "translate-x-0" : "-translate-x-[100%]"
      } fixed top-0 left-0 z-50  backdrop-blur w-full min-h-screen  transition05 flex flex-col`}
    >
      <div className="w-full max-w-[300px] bg-white shadow flex-1 flex flex-col">
        <div className="bg-PRIMARY py-2 px-4 flex items-center justify-between">
          <b className="text-white text-lg font-mono">Babaludo</b>
          <IoCloseOutline
            className="text-white"
            size={25}
            onClick={() => setSideBar(false)}
          />
        </div>
        <div className="p-4 ">
          <b className="text-2xl font-mono">
            Hey, {user?.name || user?.key_id}
          </b>
        </div>
        <div className=" flex flex-col  ">
          {ROUTES.map((r, i) => {
            if (r?.show) {
              return (
                <Link
                  to={r?.path}
                  className="p-4 flex items-center gap-2 border-b border-PRIMARY bg-slate-100"
                >
                  {r?.icon}
                  <Typography.Text className="text-xl font-semibold">
                    {r?.title}
                  </Typography.Text>
                </Link>
              );
            }
          })}
          <button
            className="p-4 flex items-center gap-2 border-b border-PRIMARY bg-slate-100 "
            onClick={onClick}
          >
            <MdInstallMobile size={20} />
            <Typography.Text className="text-xl font-semibold">
              Install App
            </Typography.Text>
          </button>
          <span
            className="p-4 flex items-center gap-2 border-b bg-slate-100 italic"
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            <Typography.Text className="text-xl font-semibold">
              Logout
            </Typography.Text>
          </span>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
