import axios from "axios";
import { message } from "antd";
import { LOCALDATAKEYS } from "./Constants";
import { SERVER_URL } from "./Configs";
// const SERVER_URL ="http://localhost:8000"
// const SERVER_URL ="http://192.168.1.38:8000"
export const get = async (endpoint,query) => {
  try {
    const token = localStorage.getItem(LOCALDATAKEYS.AUTH_TOKEN)
    const url = SERVER_URL + "/api/user" + endpoint;
    const res = await axios.get(url,{
        headers:{
            "Content-Type":"application/json",
            ...(token?{"token":token}:{})
        },
        params:{
...(query?query:{})
        }
    });
    return res.data;
  } catch (error) {
    message.error(error.message);
  }
};
export const post = async (endpoint, data) => {
  try {
    const token = localStorage.getItem(LOCALDATAKEYS.AUTH_TOKEN)
    const url = SERVER_URL + "/api/user" + endpoint;
    const res = await axios.post(url, data,{
        headers:{
            "Content-Type":"application/json",
            ...(token?{"token":token}:{})
        }
    });
    return res.data;
  } catch (error) {
    message.error(error.message);
  }
};
export const uploadImg = async (file) => {
  try {
    const formData = new FormData
    formData.append("file",file)
    const token = localStorage.getItem(LOCALDATAKEYS.AUTH_TOKEN)
    const url = SERVER_URL + "/api" + "/media/upload";
    const res = await axios.post(url, formData,{
        headers:{
            "Content-Type":"multipart/form-data",
            ...(token?{"token":token}:{})
        }
    });
    return res.data;
  } catch (error) {
    message.error(error.message);
  }
};
export const uploadImgKyc = async (file) => {
  try {
    const formData = new FormData
    formData.append("file",file)
    const token = localStorage.getItem(LOCALDATAKEYS.AUTH_TOKEN)
    const url = SERVER_URL + "/api" + "/media/upload/kyc";
    const res = await axios.post(url, formData,{
        headers:{
            "Content-Type":"multipart/form-data",
            ...(token?{"token":token}:{})
        }
    });
    return res.data;
  } catch (error) {
    message.error(error.message);
  }
};
export const put = async (endpoint, data) => {
  try {
    const token = localStorage.getItem(LOCALDATAKEYS.AUTH_TOKEN)
    const url = SERVER_URL + "/api/user" + endpoint;
    const res = await axios.put(url, data,{
        headers:{
            "Content-Type":"application/json",
            ...(token?{"token":token}:{})
        }
    });
    return res.data;
  } catch (error) {
    message.error(error.message);
  }
};
