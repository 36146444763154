import { STORE } from "../Store"
import { GetGames } from "./games.action"
import { GetSettings, GetUser } from "./user.action"



export const LoadApp =()=>{

        STORE.dispatch(GetUser())
        STORE.dispatch(GetGames())
        STORE.dispatch(GetSettings())
        

}